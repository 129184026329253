import _get from "lodash/get";
import { Alert } from 'reactstrap';
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import { Offline } from "react-detect-offline";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  componentDidMount() { }

  handleFireBaseMsg = payload => {
    const title = _get(payload, "notification.title");
    const message = _get(payload, "notification.body");
    const notifType = _get(payload, "data.type", "info");
    store.addNotification({
      title,
      message,
      type: notifType,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 10000,
        onScreen: true
      }
    });
  };

  render() {
    // if (!this.props.preCheck) {
    //   return null;
    // }
    return (
      <React.Fragment>
        <Offline>
          <Alert style={{ margin: 0, fontSize: 18, fontWeight: 600 }} className="text-center text-uppercase" color="danger"><i className="fa fa-warning"></i> You're offline right now. Check your internet connection.</Alert>
        </Offline>
        <Router>
          <ReactNotification />
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              />

              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    // preCheck: _get(state, "authData.preCheck", false)
  };
};

export default connect(
  mapStateToProps,
  {}
)(App);
